import * as React from "react"
import {
  Heading,
  Text,
  LinkButtonProps,
  LinkButton,
  ThemeCss,
} from "gatsby-interface"
import { textLinearGradient } from "../style-utils"
import { getHtml, getImage } from "../utils"
import { ColorSchemeCss } from "../color-schemes"

export interface HeroCtas {
  title: string
  variant?: LinkButtonProps["variant"]
  to: string
  rightIcon?: LinkButtonProps["rightIcon"]
  leftIcon?: LinkButtonProps["leftIcon"]
}

export interface HeroProps {
  title: string
  topLine: string
  description: string
  iconSrc?: string
  leftBackgroundSrc?: string
  rightBackgroundSrc?: string
  ctas?: Array<HeroCtas>
}

const HeroWidthDesktop = `840px`

const containerCss: ThemeCss = theme => ({
  padding: `${theme.space[10]} 0`,
  position: "relative",
})

const wrapperCss: ThemeCss = theme => ({
  position: "relative",
  zIndex: 2,
  margin: `0 ${theme.space[5]}`,

  [theme.mediaQueries.desktop]: {
    width: HeroWidthDesktop,
    margin: `0 auto`,
  },
})

const titleCss: ColorSchemeCss = theme => [
  textLinearGradient({
    startColor: theme.colorScheme.secondary[60],
    endColor: theme.colorScheme.primary[50],
  }),
  {
    color: theme.colors.purple[60],
    fontSize: theme.fontSizes[9],
    fontWeight: theme.fontWeights.extraBold,
    letterSpacing: theme.letterSpacings.tight,
    lineHeight: 1.1, // to-do add 1.1 value to theme
    maxWidth: "18ch",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",

    // i <3 responsive type array :P
    [theme.mediaQueries.mobile]: {
      fontSize: theme.fontSizes[10],
    },

    [theme.mediaQueries.phablet]: {
      fontSize: theme.fontSizes[12],
    },

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[14],
    },
  },
]

const contentCss: ThemeCss = theme => ({
  margin: `${theme.space[7]} auto ${theme.space[10]}`,
  maxWidth: "40rem",
  textAlign: `center`,

  [theme.mediaQueries.desktop]: {
    padding: `0 ${theme.space[15]}`,
  },
})

const headingReverseCss: ThemeCss = () => ({
  display: "flex",
  flexDirection: "column-reverse",
})

const ctasCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
    justifyContent: "center",
  },
})

const topLineCss: ColorSchemeCss = theme => [
  textLinearGradient({
    startColor: theme.colorScheme.secondary[60],
    endColor: theme.colorScheme.primary[60],
  }),
  {
    fontSize: theme.fontSizes[2],
    fontWeight: theme.fontWeights.semiBold,
    letterSpacing: theme.letterSpacings.tracked,
    margin: `0 0 ${theme.space[5]} 0`,
    padding: `0`,
    textAlign: "center",
    textTransform: "uppercase",

    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[3],
    },
  },
]

const linkButtonCss: ThemeCss = theme => ({
  // TODO move to gatsby-interface — all our buttons should be rounder/friendlier
  borderRadius: theme.radii[3],
  marginBottom: theme.space[5],

  "&:last-of-type": {
    marginBottom: 0,
  },

  [theme.mediaQueries.tablet]: {
    marginBottom: 0,
    marginRight: theme.space[5],

    "&:last-of-type": {
      marginRight: 0,
    },
  },
})

const imgCss: ThemeCss = theme => ({
  maxHeight: "72px",
  textAlign: "center",
})

const leftImageCss: ThemeCss = theme => ({
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
})

const rightImageCss: ThemeCss = theme => ({
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
})

export function Hero({
  title,
  topLine,
  iconSrc,
  rightBackgroundSrc,
  leftBackgroundSrc,
  description,
  ctas,
}: HeroProps) {
  return (
    <div css={containerCss}>
      {leftBackgroundSrc && (
        <img
          src={leftBackgroundSrc}
          aria-hidden={true}
          alt=""
          css={leftImageCss}
        />
      )}
      {rightBackgroundSrc && (
        <img
          src={rightBackgroundSrc}
          aria-hidden={true}
          alt=""
          css={rightImageCss}
        />
      )}

      <div css={wrapperCss}>
        <div css={imgCss}>
          <img src={iconSrc} aria-hidden={true} alt="" />
        </div>

        <div css={headingReverseCss}>
          <div>
            <Heading as="h1" css={titleCss}>
              {title}
            </Heading>
          </div>
          <div>
            <Text size="L" css={topLineCss}>
              {topLine}
            </Text>
          </div>
        </div>

        <Text
          size="L"
          css={contentCss}
          dangerouslySetInnerHTML={{ __html: description }}
        ></Text>

        {ctas && (
          <div css={ctasCss}>
            {ctas.map((cta, index) => (
              <LinkButton
                key={`hero-${index}-${cta.title}`}
                to={cta.to}
                variant={cta.variant}
                rightIcon={cta.rightIcon}
                leftIcon={cta.leftIcon}
                css={linkButtonCss}
                size="XL"
              >
                {cta.title}
              </LinkButton>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapHeroProps = (entry: any): HeroProps => {
  const iconSrc = getImage(entry.icon)
  const leftBackgroundSrc = getImage(entry.leftBackground)
  const rightBackgroundSrc = getImage(entry?.rightBackground)

  return {
    iconSrc,
    rightBackgroundSrc,
    leftBackgroundSrc,
    title: entry.title,
    topLine: entry.topLine,
    description: getHtml(
      entry.childContentfulSelfServicePageHeroDescriptionTextTextNode
    ),
  }
}
