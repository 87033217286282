import * as React from "react"
import { Heading, ThemeCss, useTheme, Text } from "gatsby-interface"
import HubspotForm from "../../hubspot-form"
import { textLinearGradient } from "../style-utils"
import { getHtml } from "../utils"
import { ColorSchemeCss } from "../color-schemes"

const Arrow = (props: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg width="25" height="115" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path stroke="#fff" d="M12.5 96.094v-96" />
    <path
      d="M23.5 91.151l-11.198 21.847L1.104 91.151l10.989 5.045.209.096.208-.096L23.5 91.15z"
      fill="#fff"
      stroke="#fff"
    />
  </svg>
)

const preHeadingCss: ThemeCss = theme => ({
  color: theme.colors.blackFade[50],
  textTransform: `uppercase`,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  marginTop: theme.space[4],
  marginBottom: 0,
  letterSpacing: theme.letterSpacings.tracked,

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[3],
  },
})

const sectionCss: ColorSchemeCss = theme => ({
  alignItems: `center`,
  background: `linear-gradient(0deg, ${theme.colorScheme.accent[10]}, ${theme.colorScheme.primary[10]} 80%)`,
  display: `flex`,
  flexDirection: `column`,
  padding: `0 ${theme.space[5]} ${theme.space[13]} `,
  textAlign: `center`,
  position: `relative`,
  zIndex: 0,
  [theme.mediaQueries.desktop]: {
    padding: `0 11rem ${theme.space[15]}`,
    borderRadius: theme.radii[4],
  },
})

const subtitleCss: ThemeCss = theme => ({
  color: theme.colors.grey[90],
  fontSize: theme.fontSizes[3],
  marginTop: theme.space[4],
  maxWidth: "30rem",
  textAlign: `center`,
})

const headingCss: ColorSchemeCss = theme => [
  textLinearGradient({
    startColor: theme.colorScheme.secondary[90],
    endColor: theme.colorScheme.primary[90],
  }),
  {
    fontSize: theme.fontSizes[8],
    color: theme.colorScheme.primary[90],
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[10],
    },
  },
]

interface ContactFormProps {
  tagLine: string
  heading: string
  lede: string
  hubspotPortalId: string
  hubspotFormId: string
}

export function ContactForm({
  tagLine,
  heading,
  lede,
  hubspotFormId,
  hubspotPortalId,
}: ContactFormProps): JSX.Element {
  const theme = useTheme()

  return (
    <section css={sectionCss}>
      <Arrow />
      <Text css={preHeadingCss}>{tagLine}</Text>
      <Heading id="contact" as="h2" css={headingCss} variant="EMPHASIZED">
        {heading}
      </Heading>
      <Text css={subtitleCss} dangerouslySetInnerHTML={{ __html: lede }}></Text>
      <HubspotForm
        portalId={hubspotPortalId}
        formId={hubspotFormId}
        wrapperCss={{
          "& .hs-form-iframe": {
            width: `100%!important`,
          },
          [theme.mediaQueries.desktop]: {
            "& .hs-form-iframe": {
              width: `600px !important`,
            },
          },

          marginTop: theme.space[10],
          textAlign: `left`,
        }}
      />
    </section>
  )
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapContactFormProps = (entry: any): ContactFormProps => ({
  heading: entry.heading,
  tagLine: entry.tagLine,
  lede: getHtml(entry.lede),
  hubspotFormId: entry.hubspotFormId,
  hubspotPortalId: entry.hubspotPortalId,
})
