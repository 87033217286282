import * as React from "react"
import { Text, Avatar, Spacer, ThemeCss, useTheme } from "gatsby-interface"
import Image, { FluidObject } from "gatsby-image"

export interface TestimonialProps {
  text: string
  person: {
    avatar: string
    name: string
    title: string
  }
  logoSvgDataURI: string
  backgroundImage?: FluidObject
}

const wrapperStyles: ThemeCss = theme => ({
  color: theme.colors.white,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  textAlign: `center`,
  margin: `0 auto`,
  width: `100%`,
  maxWidth: `65rem`,
  borderRadius: theme.radii[4],
  position: `relative`,
  boxSizing: `border-box`,
  padding: theme.space[8],
  [theme.mediaQueries.tablet]: {
    padding: `${theme.space[12]} ${theme.space[14]}`,
  },
  [theme.mediaQueries.desktop]: {
    padding: theme.space[15],
  },
})

const overlayStyles: ThemeCss = theme => ({
  width: `100%`,
  height: `100%`,
  position: `absolute`,
  top: 0,
  left: 0,
  backgroundColor: theme.colors.gatsby,
  opacity: `95%`,

  [theme.mediaQueries.desktop]: {
    borderRadius: theme.radii[4],
  },
})

const quoteStyles: ThemeCss = theme => ({
  color: theme.colors.white,
  zIndex: 1,
  fontSize: theme.fontSizes[4],
})

const nameStyles: ThemeCss = theme => ({
  margin: 0,
  color: theme.colors.white,
  zIndex: 1,
})

const titleStyles: ThemeCss = theme => ({
  margin: 0,
  color: theme.colors.whiteFade[80],
  whiteSpace: `pre-wrap`,
  zIndex: 1,
})

const backgroundImageStyles = {
  width: `100%`,
  height: `100%`,
  position: `absolute`,
  top: 0,
  left: 0,
}

const logoStyles: ThemeCss = theme => ({
  height: theme.space[7],
  zIndex: 1,
})

export function Testimonial({
  text,
  person,
  logoSvgDataURI,
  backgroundImage,
}: TestimonialProps) {
  const theme = useTheme()

  return (
    <div css={wrapperStyles}>
      {backgroundImage && (
        // Note: Need to use style={} rather than css={} here as we want to style the gatsby-image wrapper
        <Image
          alt=""
          style={{ ...backgroundImageStyles, borderRadius: theme.radii[4] }}
          fluid={backgroundImage}
        />
      )}
      <div css={overlayStyles} />
      <img alt="" src={logoSvgDataURI} css={logoStyles} />
      <Spacer size={10} />
      <blockquote css={quoteStyles}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </blockquote>
      <Spacer size={10} />
      <div css={{ display: `flex` }}>
        <Avatar
          label={`${person.name}'s avatar`}
          src={person.avatar}
          size="XL"
          css={{
            zIndex: 1,
          }}
        />
        <Spacer direction="horizontal" size={5} />
        <div
          css={{
            textAlign: `left`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
          }}
        >
          <Text size="L" css={nameStyles}>
            {person.name}
          </Text>
          <Text size="S" css={titleStyles}>
            {person.title}
          </Text>
        </div>
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapTestimonialProps = (entry: any): TestimonialProps => ({
  text: entry.quote.childMarkdownRemark.html,
  person: {
    avatar: entry.personAvatar.fixed.src,
    name: entry.personName,
    title: entry.personTitle,
  },
  logoSvgDataURI: entry.logo.svg.dataURI,
  backgroundImage: entry.backgroundImage.fluid,
})
