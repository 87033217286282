export const textLinearGradient = ({
  direction = 0,
  startColor,
  endColor,
}: {
  direction?: number
  startColor: string
  endColor: string
}) => ({
  background: `linear-gradient(${direction}deg, ${startColor},  ${endColor})`,
  WebkitBackgroundClip: `text`,
  WebkitTextFillColor: `transparent`,
  MozBackgroundClip: "text",
  MozTextFillColor: "transparent",
  paddingBottom: `0.1em`, // with condensed line-height like 1.1 text clip cuts the bottom edges
  marginBottom: `-0.1em`, // of letters like "p" or "g", this temporary hack prevents that , but that needs more investigation
})
