import { Heading, Text, ThemeCss } from "gatsby-interface"
import * as sanitizeHTML from "sanitize-html"
import * as React from "react"
import { Interpolation } from "@emotion/core"
import { getHtml } from "../utils"
import { ColorScheme, ColorSchemeCss } from "../color-schemes"

const sectionCss: ThemeCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  flexDirection: `column`,
  textAlign: `center`,
  position: `relative`,
  zIndex: 0,
  padding: theme.space[5],
  [theme.mediaQueries.desktop]: {
    padding: `${theme.space[15]} 11rem`,
  },
})

const ledeCss: ThemeCss = theme => ({
  color: theme.colors.grey[90],
  [theme.mediaQueries.desktop]: {
    margin: `0 ${theme.space[15]} ${theme.space[13]} `,
  },
})

// Sets the colour of the Nth item, but repeating if there are more items than colours
const customListColors = (colors: Array<string>): Array<Interpolation> =>
  colors.map((color, index) => ({
    [`li:nth-child(${colors.length}n+${index + 1})::before`]: {
      backgroundColor: color,
    },
  }))

const listCss = (theme: ColorScheme, initial: number): Interpolation => ({
  width: "100%",
  color: theme.colors.grey[70],
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[3],
  textAlign: "left",
  lineHeight: theme.lineHeights.loose,
  listStyle: "none",
  counterSet: `walkthrough ${initial - 1}`,
  counterReset: "walkthrough",
  padding: 0,
  marginBottom: 0,
  marginTop: theme.space[10],
  marginLeft: 0,

  [theme.mediaQueries.desktop]: {
    transform: "translate(-11px, -30px)",
  },

  strong: {
    fontWeight: theme.fontWeights.semiBold,
  },
  code: {
    fontFamily: theme.fonts.monospace,
  },
  li: {
    counterIncrement: "walkthrough",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.space[10],
    alignItems: "center",
    width: "100%",
    textAlign: "center",

    [theme.mediaQueries.desktop]: {
      flexDirection: "row",
      textAlign: "left",
      marginTop: theme.space[7],
    },
  },
  "li::before": {
    content: "counter(walkthrough)",
    display: "flex",
    fontSize: theme.fontSizes[0],
    color: `white`,
    borderRadius: 20,
    width: 20,
    height: 20,
    backgroundColor: theme.colorScheme.secondary[90],
    placeContent: "center",
    marginBottom: theme.space[3],

    [theme.mediaQueries.desktop]: {
      marginRight: theme.space[10],
      marginBottom: "unset",
    },
  },

  "span p:first-of-type": {
    display: "inline",
  },
})

const wrapperCss: ThemeCss = theme => ({
  width: "100%",
  alignItems: "stretch",

  [theme.mediaQueries.desktop]: {
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.grey[30],
  },
})

const headingCss: ColorSchemeCss = theme => ({
  fontSize: theme.fontSizes[10],
  color: theme.colorScheme.secondary[60],
  marginBottom: theme.space[7],
})

const timeCss: ThemeCss = theme => ({
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  paddingLeft: 16,
  marginLeft: theme.space[3],
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left center",
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 1h-3v1h3V1zm-2 6.5h1v-3h-1v3zm4.015-3.305l.71-.71a5.524 5.524 0 00-.705-.705l-.71.71A4.48 4.48 0 006 2.5 4.5 4.5 0 1010.5 7c0-1.06-.37-2.035-.985-2.805zM6 10.5A3.497 3.497 0 012.5 7c0-1.935 1.565-3.5 3.5-3.5S9.5 5.065 9.5 7 7.935 10.5 6 10.5z' fill='%23000' fill-opacity='.5'/%3E%3C/svg%3E");`,
})

const calloutCss: ColorSchemeCss = theme => ({
  margin: 0,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.colorScheme.secondary[10],
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[1],
  borderRadius: theme.radii[3],
  color: theme.colors.grey[70],
  display: "inline-block",
  padding: `${theme.space[5]} ${theme.space[10]}`,
  "a:link,a:visited": {
    color: theme.colorScheme.secondary[50],
  },
  backgroundRepeat: "no-repeat",
  backgroundPosition: `${theme.space[5]} center`,
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1.667A8.336 8.336 0 001.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667zm.833 12.5H9.167v-5h1.666v5zm0-6.667H9.167V5.833h1.666V7.5z' fill='%23B17ACC'/%3E%3C/svg%3E");`,
})

export interface WalkThroughItem {
  text: string
  time?: string
}

export interface WalkThroughProps {
  heading?: string
  lede?: string
  initial?: number
  items: Array<WalkThroughItem>
  callout?: string
}

export function WalkThrough({
  heading,
  lede,
  initial = 1,
  items = [],
  callout,
}: WalkThroughProps): JSX.Element {
  return (
    <section css={sectionCss}>
      {heading && (
        <Heading as="h1" css={headingCss}>
          {heading}
        </Heading>
      )}
      {lede && (
        <Text
          size="XL"
          css={ledeCss}
          dangerouslySetInnerHTML={{ __html: lede }}
        />
      )}
      <div css={wrapperCss}>
        <ol
          css={(theme: ColorScheme) => [
            listCss(theme, initial),
            customListColors([
              theme.colors.purple[60],
              theme.colors.magenta[50],
              theme.colors.red[50],
              theme.colors.orange[60],
              theme.colors.yellow[50],
            ]),
          ]}
        >
          {items.map((item, index) => {
            return (
              <li key={`${item.text} ${index}`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
                {item.time && <time css={timeCss}>{item.time}</time>}
              </li>
            )
          })}
        </ol>
        {callout && (
          <Text
            size="S"
            css={calloutCss}
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(callout) }}
          />
        )}
      </div>
    </section>
  )
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const mapWalkthroughProps = (entry: any): WalkThroughProps => {
  const items = entry.steps.map(
    (step: any): WalkThroughItem => ({
      text: getHtml(step.title),
      time: step.time,
    })
  )

  return {
    items,
    callout: getHtml(entry.callout),
    heading: entry.heading,
    initial: entry.initial,
    lede: getHtml(entry.lede),
  }
}
