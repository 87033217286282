import React from "react"
import { graphql } from "gatsby"
import { Spacer } from "gatsby-interface"
import { ColorSchemeProvider } from "../../components/self-serving-landing-page/color-scheme-provider"
import Layout from "../../layouts"
import Header from "../../components/header"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import {
  Hero,
  mapHeroProps,
} from "../../components/self-serving-landing-page/components/Hero"
import {
  FeatureGrid,
  mapFeatureGridProps,
} from "../../components/self-serving-landing-page/components/FeatureGrid"
import {
  ContactForm,
  mapContactFormProps,
} from "../../components/self-serving-landing-page/components/ContactForm"
import {
  Testimonial,
  mapTestimonialProps,
} from "../../components/self-serving-landing-page/components/Testimonial"

import {
  WalkThrough,
  mapWalkthroughProps,
} from "../../components/self-serving-landing-page/components/WalkThrough"

const ComponentsMap = {
  ContentfulSelfServicePageHero: { component: Hero, mapProps: mapHeroProps },
  ContentfulSelfServicePageFeatureGrid: {
    component: FeatureGrid,
    mapProps: mapFeatureGridProps,
  },
  ContentfulSelfServicePageContactForm: {
    component: ContactForm,
    mapProps: mapContactFormProps,
  },
  ContentfulSelfServicePageTestimonial: {
    component: Testimonial,
    mapProps: mapTestimonialProps,
  },
  ContentfulSelfServicePageWalkthrough: {
    component: WalkThrough,
    mapProps: mapWalkthroughProps,
  },
}

const prepareComponents = data => {
  const safeData = data?.contentfulSelfServicePagePage?.contentfulfields || []

  return safeData.map((entry, index) => {
    const componentDefinition = ComponentsMap[entry.__typename]

    if (componentDefinition) {
      const Component = componentDefinition.component
      const props = componentDefinition.mapProps(entry)

      return (
        <React.Fragment key={`${entry.__typename}-${index}`}>
          <Component {...props} />
          <Spacer
            responsiveSize={{
              desktop: 15,
              mobile: 0,
            }}
          />
        </React.Fragment>
      )
    }

    return null
  })
}

const mainCss = theme => ({
  [theme.mediaQueries.desktop]: {
    padding: theme.space[15],
  },
})

export default function({ data, location }) {
  const Components = prepareComponents(data)

  const { pathname } = location

  return (
    <Layout pathname={pathname}>
      <ColorSchemeProvider>
        <SEO
          title={
            data.contentfulSelfServicePagePage.title ||
            data.contentfulSelfServicePagePage.name
          }
          description={data.contentfulSelfServicePagePage.metaDescription}
          socialMediaImageUrl={
            data.contentfulSelfServicePagePage.socialMediaImage?.file?.url
          }
        />
        <Header applyTopPadding={false} isFullWidth={true} isFixed />

        <Spacer size={15} />

        <main css={mainCss}>{Components}</main>

        <Footer />
      </ColorSchemeProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SelfServiceLandingPage($id: String!) {
    contentfulSelfServicePagePage(id: { eq: $id }) {
      id
      slug
      title
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      contentfulfields {
        __typename
        ... on ContentfulSelfServicePageFeatureGrid {
          __typename
          heading
          childContentfulSelfServicePageFeatureGridLedeTextNode {
            childMarkdownRemark {
              html
            }
          }
          featureGridItems {
            title
            childContentfulTitleAndTextTextTextNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on ContentfulSelfServicePageHero {
          __typename
          title
          topLine
          childContentfulSelfServicePageHeroDescriptionTextTextNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            file {
              url
            }
          }
          leftBackground {
            file {
              url
            }
          }
          rightBackground {
            file {
              url
            }
          }
        }
        ... on ContentfulSelfServicePageContactForm {
          id
          heading
          hubspotFormId
          hubspotPortalId
          lede {
            childMarkdownRemark {
              html
            }
          }
          tagLine
        }
        ... on ContentfulSelfServicePageTestimonial {
          __typename
          quote {
            childMarkdownRemark {
              html
            }
          }
          logo {
            svg {
              dataURI
            }
            fixed {
              ...GatsbyContentfulFixed
            }
          }
          personAvatar {
            fixed {
              ...GatsbyContentfulFixed
            }
          }
          backgroundImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          personName
          personTitle
        }

        ... on ContentfulSelfServicePageWalkthrough {
          __typename
          lede {
            childMarkdownRemark {
              html
            }
          }
          heading
          steps {
            title {
              childMarkdownRemark {
                html
              }
            }
            time
          }
          callout {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
