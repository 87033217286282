import { Heading, Text, ThemeCss } from "gatsby-interface"
import * as sanitizeHTML from "sanitize-html"
import * as React from "react"
import { getHtml } from "../utils"
import { ColorSchemeCss } from "../color-schemes"

const sectionCss: ColorSchemeCss = theme => ({
  alignItems: `center`,
  background: `linear-gradient(0deg, ${theme.colorScheme.accent[10]}, ${theme.colorScheme.primary[10]})`,
  display: `flex`,
  flexDirection: `column`,
  padding: `${theme.space[11]} ${theme.space[5]} ${theme.space[13]}`,
  textAlign: `center`,
  position: `relative`,
  zIndex: 0,
  [theme.mediaQueries.desktop]: {
    padding: `${theme.space[15]} 11rem`,
    borderRadius: theme.radii[4],
  },
})

const gridCss: ThemeCss = theme => ({
  width: `100%`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[5],
  textAlign: "left",
  color: theme.colors.grey[90],
  marginTop: theme.space[10],
  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.space[12],
  },
})

const ledeCss: ThemeCss = theme => ({
  color: theme.colors.grey[90],
  [theme.mediaQueries.desktop]: {
    margin: `${theme.space[7]} ${theme.space[15]} ${theme.space[13]} `,
  },
})

const gridHeadingCss: ColorSchemeCss = theme => ({
  color: theme.colors.grey[90],
  "em, strong": {
    fontStyle: "normal",
    fontWeight: "inherit",
    color: theme.colorScheme.primary[60],
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[10],
  color: theme.colors.teal[90],
})

export interface FeatureGridItem {
  // Heading can contain HTML
  heading: string
  text: string
  // image
}

export interface FeatureGridProps {
  heading: string
  lede: string
  items: Array<FeatureGridItem>
}

export function FeatureGrid({
  heading,
  lede,
  items = [],
}: FeatureGridProps): JSX.Element {
  return (
    <section css={sectionCss}>
      <Heading as="h1" css={headingCss}>
        {heading}
      </Heading>
      <Text
        size="XL"
        css={ledeCss}
        dangerouslySetInnerHTML={{
          __html: lede,
        }}
      ></Text>
      <div css={gridCss}>
        {items.map(item => {
          return (
            <div key={item.heading}>
              <Heading
                as="h2"
                css={gridHeadingCss}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(item.heading, {
                    allowedTags: ["em", "strong"],
                  }),
                }}
              />
              <Text
                size="L"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(item.text, {
                    allowedTags: ["em", "strong"],
                  }),
                }}
              ></Text>
            </div>
          )
        })}
      </div>
    </section>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapFeatureGridProps = (entry: any): FeatureGridProps => {
  const items =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entry.featureGridItems?.map((item: any) => ({
      heading: item.title,
      text: getHtml(item.childContentfulTitleAndTextTextTextNode),
    })) ?? []

  return {
    items,
    heading: entry.heading ?? "",
    lede:
      getHtml(entry.childContentfulSelfServicePageFeatureGridLedeTextNode) ??
      "",
  }
}
