import { Theme } from "gatsby-interface"
import { Interpolation } from "@emotion/core"

export type ColorScheme = Theme & {
  colorScheme: {
    primary: string
    secondary: string
    accent: string
  }
}

export type ColorSchemeCss = (theme: ColorScheme) => Interpolation

export type ColorSchemes = `DEFAULT` | `WORDPRESS` | `CONCIERGE`

export const colorSchemes = (interfaceTheme: Theme) => ({
  DEFAULT: {
    primary: interfaceTheme.colors.purple,
    secondary: interfaceTheme.colors.purple,
    accent: interfaceTheme.colors.magenta,
  },
  CONCIERGE: {
    primary: interfaceTheme.colors.magenta,
    secondary: interfaceTheme.colors.purple,
    accent: interfaceTheme.colors.magenta,
  },
  WORDPRESS: {
    primary: {
      // colorScheme expects a color range from gatsby-interface for `primary`, `secondary`, etc.
      // thank you, https://hihayk.github.io/scale/#4/5/65/92/-10/12/9/7/00749A/0/116/154/white
      90: "#002038",
      80: "#003251",
      70: "#00466A",
      60: "#005C82",
      50: "#00749A",
      40: "#2F93AE",
      30: "#5EB0C1",
      20: "#8DCAD4",
      10: "#BCE2E6",
      5: "#EBF7F8",
    },
    secondary: interfaceTheme.colors.purple,
    accent: interfaceTheme.colors.magenta,
  },
})
